export class Host {

    public name!: string;

    public versions!: {
        backend: string;
        nodejs: string;
    };
}

export class Journal {

    raw = '';
}
