<wui-modal-layout [wuiDisabled]="isLoading">

    <div title>{{ title }}</div>

    <app-header header logo="assets/products/nfcTagOperatorDark.svg" [description]="header"></app-header> <!-- TODO ADD A COLOR OPTION, MOVE TO WUI ?-->

    <div body>

        <wui-steps [steps]="tabs" usersOnly></wui-steps>

        @switch (tabs.selected?.selector) {

            @case ('instructions') {
                <wui-section name="instructions">
                    Please follow these instructions:
                    <ul>
                        <li>You have to know the id of the account of the operator.</li>
                        <li>Take the right model of a brand new (never registered) operator tag.</li>
                        <li>Put it on the work board.</li>
                        <li>Press Continue.</li>
                    </ul>
                </wui-section>
            }

            @case ('serial') {
                <wui-section name="tag">
                    <wui-entry>
                        <div name>Serial</div>
                        <wui-input value [(value)]="request.serial" [required]="true" pattern="([0-9 A-F]{2}[:]){6}([0-9 A-F]{2})" (click)="readNfcTagUid()"></wui-input>
                        <div detail>Press 'read serial' to autofill this field.</div>
                    </wui-entry>
                    <wui-entry>
                        <div name>Hardware version</div>
                        <wui-input value [(value)]="request.version" [required]="true" pattern="^([1-9]\d*|0)\.([1-9]\d*|0)\.([1-9]\d*|0)$"></wui-input>
                    </wui-entry>
                    <wui-entry>
                        <div name>Life time</div>
                        <wui-input value [(value)]="request.lifeTime" type="number" [min]="1" [required]="true"></wui-input>
                        <div detail>Validity in years.</div>
                    </wui-entry>
                </wui-section>
            }

            @case ('account') {
                <wui-section name="account">
                    <wui-entry>
                        <div name>Id</div>
                        <wui-input value
                            [(value)]="request.accountId"
                            (valueChange)="accountIdChanged()"
                            pattern="^[0-9a-f]{24}$"
                            [required]="true">
                        </wui-input>
                        <div detail *ngIf="request.accountId">
                            <ng-container *ngIf="account">
                                <ng-container *ngIf="operatorProfile === undefined">
                                    This account doesn't have an operator profile yet.
                                </ng-container>
                                <ng-container *ngIf="operatorProfile">
                                    This account already have an operator profile!
                                </ng-container>
                            </ng-container>
                        </div>
                    </wui-entry>
                </wui-section>
            }

            @case ('operator') {
                <wui-section [name]="operatorProfile ? 'operator ' + operatorProfile.number : 'new operator'">

                    <ng-container *ngIf="operatorProfile">

                        <wui-entry>
                            <div name>Number</div>
                            <div value>{{ operatorProfile.number }}</div>
                        </wui-entry>
                        <wui-entry>
                            <div name>Status</div>
                            <div value>{{ operatorProfile.status }}</div>
                        </wui-entry>
                    </ng-container>

                    <wui-entry>
                        <div name>Name</div>
                        <wui-input value [(value)]="request.operatorName" [required]="true"></wui-input>
                        <div detail *ngIf="operatorProfile && operatorProfile.name !== request.operatorName">
                            Warning: You modified this option from an existing operator.
                        </div>
                    </wui-entry>
                </wui-section>
            }

            @case ('write') {
                <wui-section name="write">
                    <wui-entry *ngIf="nfcTag">
                        <div name>Records</div>
                        <div value>
                            <div *ngFor="let record of nfcTag.records" class="record">{{ record }}</div>
                        </div>
                    </wui-entry>
                </wui-section>
            }

            @case ('complete') {
                <wui-section name="complete">
                    The tag is ready to use and registered as device id {{ nfcTag?.id }}.
                </wui-section>
            }
        }
    </div>

    <wui-buttons footer>

        @switch (tabs.selected?.selector) {

            @case ('instructions') {
                <wui-button (click)="close()">cancel</wui-button>
                <wui-button (click)="readNfcTagUid(); continue()">continue</wui-button>
            }

            @case ('serial') {
                <wui-button (click)="back()">back</wui-button>
                <wui-button (click)="continue()" [wuiDisabled]="!request.serial || !request.version">continue</wui-button>
            }

            @case ('account') {
                <wui-button (click)="back()">back</wui-button>
                <wui-button (click)="continue()" [wuiDisabled]="!request.accountId">continue</wui-button>
            }

            @case ('operator') {
                <wui-button (click)="back()">back</wui-button>

                @if(operatorProfile === undefined) {
                    <wui-button (click)="createOperatorProfile()" [wuiDisabled]="!request.operatorName">create operator profile</wui-button>
                } @else {
                    <wui-button (click)="createNfcTagAndContinue()" [wuiDisabled]="operatorProfile === undefined">continue</wui-button>
                }
            }

            @case ('write') {
                <wui-button [wuiDisabled]="true">back</wui-button>
                <!-- <wui-button (click)="getLaserMarkingProject()">mark itxx</wui-button> -->
                <wui-button (click)="writeNfcTagAndContinue()">write</wui-button>
            }

            @case ('complete') {
                <wui-button (click)="close()">close</wui-button>
                <wui-button (click)="getLaserMarkingProject()">mark it</wui-button>
                <wui-button (click)="again()">again</wui-button>
            }
        }

    </wui-buttons>
</wui-modal-layout>
