import { Component, OnInit } from '@angular/core';
import { environment } from '@lightning/backoffice/environments/environment';
import { AppsUtils } from '@lightning/configuration';
import { OnlineService } from '@lightning/lightning-services';
import { finalize } from 'rxjs';

@Component({
    selector: 'app-state',
    templateUrl: './state.component.html',
    styleUrls: ['./state.component.scss']
})
export class StateComponent implements OnInit {

    public raw = '';

    private isLoading = false;

    constructor(
        private onlineService: OnlineService) { }

    ngOnInit(): void {

        this.isLoading = true;

        if(this.onlineService.backendUrl.includes('localhost')) {

            this.raw = 'journalctl not available locally.';

        } else {
            this.onlineService
            .getJournal()
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response) => {
                    this.raw = response.raw.replace(/\n/g,'<br/>')
                }
            });
        }
    }

    public openSwagger(): void {

        window.open(`${AppsUtils.resolveAppUrl('backend')}/swagger`, `_blank`);
    }

    public openSentry(): void {

        window.open(environment.sentry.url, `_blank`);
    }
}
