
import { IsOptional, IsString, Matches } from '@lightning/decorators';
import { AccountStatus } from '../enums/account-status.enum';


export class Address {

    @IsOptional()
    public id?: any;

    @IsString()
    public firstName = '';

    @IsString()
    public lastName = '';

    @IsString()
    public street = '';

    @IsString()
    @Matches(/^\d{5}$/)
    public postalCode = '';

    @IsString()
    public city = '';

    @IsString()
    public country = 'France';

    // @IsOptional()
    // @IsPhoneNumber()
    public phone = '';
}

export class AccountJWTPayload {

    public id?: any;

    public email = '';

    public name = '';

    public status = AccountStatus.Unconfirmed;

    public exp = 0;

    public roles = new Array<string>();

    public operatorId?: string;
}

export class AccountConfirmation {

    public code = '';

    public expiration = new Date();

}

export class Account extends AccountJWTPayload {

    public password? = '';

    public confirmation?: AccountConfirmation;

    public addresses = new Array<Address>();
}
