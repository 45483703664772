import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppsUtils, cicd } from '@lightning/configuration';
import { OverlayService } from '@lightning/wild-ui';
import { Host } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { WorkBoardService } from '../shared/services/work-board/work-board.service';
import { DevicesGetComponent } from './devices/components/devices-get/devices-get.component';
import { DeviceReadComponent } from './devices/components/device-read/device-read.component';
import { environment } from '@lightning/backoffice/environments/environment';

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements OnInit {

    public host?: Host;

    public cicd = cicd;

    constructor(
        public onlineService: OnlineService,
        public workBoardService: WorkBoardService,
        private overlayService: OverlayService,
        private router: Router) { }

    ngOnInit(): void {

        this.onlineService.getHost().subscribe({
            next: (host: Host) => {
                this.host = host;
            }
        })
    }

    public get isFullscreen() {
        return document.fullscreenElement != null;
    }

    public enterFullscreen() {
        document.documentElement.requestFullscreen();
    }

    public exitFullscreenCancel() {
        document.exitFullscreen();
    }

    public toggleFullscreen() {

        if (this.isFullscreen) {
            this.exitFullscreenCancel();
        } else {
            this.enterFullscreen();
        }
    }

    public logout(): void {

        this.onlineService.logout();

        this.router.navigate(['login']);
    }

    public openWebsite(): void {

        window.open(AppsUtils.resolveAppUrl('website'), `_blank`);
    }

    public openHeadquarter(): void {

        window.open(AppsUtils.resolveAppUrl('headquarter'), `_blank`);
    }

    public async readDevice(): Promise<void> {

        const nfcData = await this.workBoardService.readNfcTag();

        await this.overlayService.openModal({
            component: DeviceReadComponent,
            inputs: {
                title: 'readed device tag',
                nfcData
            }
        });
    }

    public async getDevice(): Promise<void> {

        await this.overlayService.openModal({
            component: DevicesGetComponent,
            inputs: {
                title: 'get device information'
            }
        });
    }

}
