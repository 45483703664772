import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService } from '@lightning/wild-ui';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

    private isOffline = false;

    // private isHidden = false;

    constructor(
        private translateService: TranslateService,
        private overlayService: OverlayService) {

            // document.addEventListener('visibilitychange', () => {
            //     this.isHidden = document['hidden'];
            // }, false);
        }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        // Intercept errors to notify
        return next.handle(request)
            .pipe(
                catchError((response: HttpErrorResponse) => {

                    let message = '';

                    if (response.status > 0) {

                        // Error from the backend
                        message = this.translateService.instant('http' + response.status) + (response.error.message ? ' : ' + response.error.message : '');

                    } else {

                        // TODO: Disabled 'is offline' notification because it continue to be displayed when a device is just wake up (the isHidden checking is not enough)
                        // So it's better to don't display it instead to make false alarms to the visitors

                        // //
                        // if(this.isHidden) {
                        //     return throwError(response);
                        // }

                        // // Offline (message diplayed only the first failure)
                        // if (this.isOffline === false) {
                        //     message = this.translateService.instant('http.offline');
                        // }

                        this.isOffline = true;
                    }

                    if (message) {
                        this.overlayService.openNotification({
                            message,
                            type: 'error'
                        });
                    }

                    return throwError(response);
                })
            )
            .pipe(
                tap(() => this.isOffline = false ));

    }
}
